<template>
  <div>
    <h1 class="h3 mb-4 text-gray-800">Dashboard</h1>
    <div v-if="! loading">
      <div class="row mb-5">
      <div class="col-md-4">
        <div class="card shadow">
          <div class="card-body">
            <div class="d-flex">
              <div class="centered pe-4">
                <i class="fal fa-building" style="font-size: 40px"></i>
              </div>
              <div class="centered">
                <div>
                  <h6 class="mb-0">Organizations</h6>
                  <h3 class="mb-0">{{ stats.orgs }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow">
          <div class="card-body">
            <div class="d-flex">
              <div class="centered pe-4">
                <i class="fal fa-list" style="font-size: 40px"></i>
              </div>
              <div class="centered">
                <div>
                  <h6 class="mb-0">Projects</h6>
                  <h3 class="mb-0">{{ stats.projects }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow">
          <div class="card-body">
            <div class="d-flex">
              <div class="centered pe-4">
                <i class="fal fa-bookmark" style="font-size: 40px"></i>
              </div>
              <div class="centered">
                <div>
                  <h6 class="mb-0">Available Credits</h6>
                  <h3 class="mb-0">{{ stats.credits }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h4>Carbon Credits</h4>
            <img src="/assets/no-data.png" alt="no-data" class="p-5 w-100" />
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <router-link
              :to="{ name: 'admin.projects' }"
              class="btn btn-primary btn-sm float-end"
              ><i class="fa fa-eye me-2"></i>View All</router-link
            >
            <h4>My Latest Projects</h4>
            <hr />
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Organization</th>
                  <th>Credits</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="projects.length == 0">
                  <td colspan="5">There is no data to display</td>
                </tr>
                <tr v-for="(project, i) in projects" :key="`project-${i}`">
                  <td>{{ project.name }}</td>
                  <td>{{ project.organization }}</td>
                  <td>{{ project.credits }}</td>
                  <td>{{ project.date }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.my-projects.show', params: { id: project.id }}">Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      stats: {},
      projects: [],
      loading: true
    };
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/admin/home').then(response => {
        this.projects = response.data.projects
        this.stats = response.data.stats
        this.loading = false
      })
    }
  }
};
</script>
