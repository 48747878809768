<template>
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <router-link
      class="sidebar-brand d-flex align-items-center justify-content-center my-2 my-md-2 p-0"
      :to="{ name: 'dashboard' }"
    >
      <img src="/logo-light.svg" alt="logo2" class="desktop-only" style="height: 120px;">
      <img src="/logo-light.svg" alt="logo2" class="mobile-only w-100">
    </router-link>

    <hr class="sidebar-divider my-0" />

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin' }">
        <i class="fa fa-tachometer-alt me-2"></i>
        <span>Dashboard</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.organizations' }">
        <i class="fa fa-building me-2"></i>
        <span>Organizations</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.projects' }">
        <i class="fa fa-building me-2"></i>
        <span>Projects</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.orders' }">
        <i class="fa fa-building me-2"></i>
        <span>Orders</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.settings' }">
        <i class="fa fa-building me-2"></i>
        <span>Settings</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'cms' }" target="_blank">
        <i class="fa fa-building me-2"></i>
        <span>Site Content</span></router-link
      >
    </li>

    <hr>
    
    <div class="sidebar-heading">Accounts & Profiles</div>

    <li class="nav-item">
      <a href="#" @click.prevent="logout" class="nav-link">
        <i class="fa fa-sign-out me-2"></i>
        <span>Log Out</span></a
      >
    </li>

    <div class="text-center d-none d-md-inline mt-4">
      <button class="rounded-circle border-0" id="sidebarToggle" @click.prevent="toggleSideMenu"></button>
    </div>
  </ul>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$axios.post('/api/v1/logout').then(() => {
        this.$storage.remove('bearer_token')
        window.location.href = this.$router.resolve({ name: 'login' }).href
      })
    }
  }
};
</script>
