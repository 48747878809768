<template>
  <div>
    <h5 class="mb-4">Account Security</h5>
    <form @submit.prevent="submit" id="security_form">
      <div class="row">
        <div class="col-md-12 mb-3">
          <div class="form-floating w-100">
            <input
              type="text"
              class="form-control"
              id="current_password"
              placeholder="Current Password"
              name="current_password"
              v-model="user.current_password"
            />
            <label for="current_password">Current Password</label>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="form-floating w-100">
            <input
              type="text"
              class="form-control"
              id="new_password"
              placeholder="New Password"
              name="new_password"
              v-model="user.new_password"
            />
            <label for="new_password">New Password</label>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="form-floating w-100">
            <input
              type="text"
              class="form-control"
              id="new_password_confirmation"
              placeholder="Confirm New Password"
              name="new_password_confirmation"
              v-model="user.new_password_confirmation"
            />
            <label for="new_password_confirmation">Confirm New Password</label>
          </div>
        </div>
        <div class="col-md-12 mb-3 text-end">
          <button class="btn btn-primary btn-sm"><i class="fa fa-save me-2"></i>Save</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {}
    }
  }
}
</script>