<template>
  <div>
    <div class="mobile-top-bar mobile-only bg-primary">
      <div class="d-flex justify-content-between">
        <router-link :to="{ name: 'admin' }" class="px-2 pt-1">
          <img
            src="/logo2-light.svg"
            alt="logo2"
            style="height: 25px"
            class="mobile-logo"
          />
        </router-link>
        <div class="d-flex justify-content-end mobile-top-nav">
          <div class="dropdown d-inline-block me-2 language-selector no-arrow">
            <a
              class="dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              href="#"
            >
              <img src="/theme/flag.svg" alt="flag" />
              <span class="text-white">En</span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item text-dark" href="#"
                  ><img src="/theme/flag.svg" alt="flag" /> English</a
                >
              </li>
            </ul>
          </div>
          <div
            class="dropdown d-inline-block me-2 theme-selector me-3 no-arrow"
          >
            <a
              class="btn btn-link btn-sm dropdown-toggle p-0"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="/assets/dark-mode.png"
                alt="dark-mode"
                style="height: 29px"
              />
            </a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item text-dark" href="#"
                  ><i class="fa fa-sun-o me-2"></i> Light</a
                >
              </li>
            </ul>
          </div>
          <div
            class="dropdown d-inline-block me-2 theme-selector me-3 no-arrow"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="searchDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-search fa-fw"></i>
            </a>
            <div
              class="dropdown-menu dropdown-menu-end p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form
                class="form-inline me-auto w-100 navbar-search search-projects"
              >
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-link" type="button">
                      <i class="fa fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav
      class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"
    >
      <button
        id="sidebarToggleTop"
        class="btn btn-link d-md-none rounded-circle me-3"
        @click.prevent="toggleSideMenu"
      >
        <i class="fa fa-bars"></i>
      </button>

      <div
        class="d-none d-sm-inline-block form-inline me-auto ms-md-3 my-2 my-md-0 mw-100 navbar-search"
      >
        <form class="search-projects">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <div class="input-group-append">
              <button class="btn btn-link" type="button">
                <i class="fal fa-search fa-sm"></i>
              </button>
            </div>
          </div>
        </form>
      </div>

      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown no-arrow mx-1 desktop-only">
          <button
            class="nav-link dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src="/theme/flag.svg" alt="flag" />
            <span class="ms-2 text-dark">En</span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" href="#"
                ><img src="/theme/flag.svg" alt="flag" /> English</a
              >
            </li>
          </ul>
        </li>

        <li class="nav-item dropdown no-arrow mx-1 desktop-only">
          <button
            class="nav-link dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="/assets/dark-mode.png"
              alt="dark-mode"
              style="height: 29px"
            />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" href="#"
                ><i class="fa fa-sun-o me-2"></i> Light</a
              >
            </li>
          </ul>
        </li>

        <li class="nav-item dropdown no-arrow mx-1">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="alertsDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-bell fa-fw"></i>
            <span class="badge bg-danger badge-counter d-none">0</span>
          </a>
          <div
            class="dropdown-list dropdown-menu dropdown-menu-end shadow animated--grow-in"
            aria-labelledby="alertsDropdown"
          >
            <h6 class="dropdown-header">Alerts Center</h6>
            <a class="dropdown-item d-flex align-items-center d-none" href="#">
              <div class="me-3">
                <div class="icon-circle bg-primary">
                  <i class="fa fa-file text-white"></i>
                </div>
              </div>
              <div>
                <div class="small text-gray-500">December 12, 2019</div>
                <span class="font-weight-bold"
                  >A new monthly report is ready to download!</span
                >
              </div>
            </a>
            <a href="#" class="dropdown-item d-flex align-items-center">There are 0 alerts</a>
            <router-link class="dropdown-item text-center small text-gray-500" :to="{ name: 'admin.alerts' }"
              >Show All Alerts</router-link
            >
          </div>
        </li>

        <li class="nav-item dropdown no-arrow mx-1">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="messagesDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-envelope-open"></i>
            <span class="badge badge-danger badge-counter d-none">0</span>
          </a>
          <div
            class="dropdown-list dropdown-menu dropdown-menu-end shadow animated--grow-in"
            aria-labelledby="messagesDropdown"
          >
            <h6 class="dropdown-header">Message Center</h6>
            <a class="dropdown-item d-flex align-items-center d-none" href="#">
              <div class="dropdown-list-image me-3">
                <img class="rounded-circle" src="/theme/user.png" alt="..." />
                <div class="status-indicator bg-success"></div>
              </div>
              <div class="font-weight-bold">
                <div class="text-truncate">
                  Hi there! I am wondering if you can help me with a problem
                  I've been having.
                </div>
                <div class="small text-gray-500">Emily Fowler · 58m</div>
              </div>
            </a>
            <a class="dropdown-item d-flex align-items-center" href="#">
              You have 0 messages.
            </a>
            <router-link class="dropdown-item text-center small text-gray-500" :to="{ name: 'admin.messages' }"
              >Read More Messages</router-link
            >
          </div>
        </li>

        <div class="topbar-divider d-none d-sm-block"></div>

        <li class="nav-item dropdown no-arrow">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="userDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="me-2 d-none d-lg-inline text-gray-600 small"
              >{{ user.name }}</span
            >
            <img class="img-profile rounded-circle" src="/theme/user.png" />
          </a>
          <div
            class="dropdown-menu dropdown-menu-end shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <router-link class="dropdown-item" :to="{ name: 'admin.my-account' }">
              <i class="fa fa-user fa-sm fa-fw me-2 text-gray-400"></i>
              My Account
            </router-link>
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="logout"
            >
              <i class="fa fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  methods: {
    toggleSideMenu() {
      document.body.classList.toggle("sidebar-toggled");

      Object.values(document.getElementsByClassName("sidebar")).forEach(
        (el) => {
          el.classList.toggle("toggled");
        }
      );
    },

    logout() {
      this.$axios.post('/api/v1/logout').then(() => {
        this.$storage.remove('bearer_token')
        window.location.href = this.$router.resolve({ name: 'login' }).href
      })
    }
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser
    }
  }
};
</script>
